.card-type {
    font-family: 'DM Sans', sans-serif;
}

.card-shadow {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.button-text {
    font-family: 'DM Sans', sans-serif;
    color: #BE9BCB;
    text-decoration: 'none';
}